export const cleanPriceListData = (rawPriceList) => {
  var index = 0;
  const processedData = rawPriceList.data[0].data
    .map((item) => {
      const cleanedPricePerPoundFullCase = cleanNumberField(
        item,
        "Price/lb (Full Case)"
      );
      const cleanedPricePerPoundHalfCase = cleanNumberField(
        item,
        "Price/lb (1/2 Case)"
      );
      const cleanedPricePerCase = cleanNumberField(item, "Price/cs");
      const cleanedPoundsPerCase = cleanNumberField(item, "lb/cs");
      const cleanedAvailablePounds = cleanNumberField(item, "Avail. lbs.");
      const cleanedPricePerEach = cleanNumberField(item, "Price/ea");
      const cleanedEachPercase = cleanNumberField(item, "ea/cs");
      if (cleanedAvailablePounds !== null) {
        const result = {
          id: index,
          label: item["Product"],
          productGroup: item["Product Group"],
          grown: item["Grown"],
          description: item["Description"],
          pricePerPoundFullCase: cleanedPricePerPoundFullCase,
          pricePerPoundHalfCase: cleanedPricePerPoundHalfCase,
          pricePerCase: cleanedPricePerCase,
          poundsPerCase: cleanedPoundsPerCase,
          availablePounds: cleanedAvailablePounds,
          pricePerEach: cleanedPricePerEach,
          eachPerCase: cleanedEachPercase,
          packingUnit: item["Packing Unit"],
        };
        index += 1;
        return result;
      }
      return undefined;
    })
    .filter((item) => item !== undefined);
  return processedData;
};

function cleanNumberField(item, fieldName) {
  if (item[fieldName] === undefined) {
    return null;
  }
  return item[fieldName] !== ""
    ? Number(item[fieldName].replace(/[^0-9.-]+/g, ""))
    : null;
}
