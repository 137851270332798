import "@aws-amplify/ui-react/styles.css";
import React, { useState } from "react";
import Grid from "@mui/material/Grid";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import { calculateTotalCost } from "../helpers/TotalCostCalculator";

const OrderItemRow = function OrderItemRow({
  index,
  item,
  setReadyOrderItem,
  orderHistory,
}) {
  const orderHistoryTotalCost = (
    index,
    item,
    quantityTypeValue,
    quantityValue
  ) => {
    const currentItem = {};
    currentItem.item = item;
    currentItem.quantityType = quantityTypeValue;
    currentItem.quantity = Number(quantityValue);
    var totalCost = null;
    if (currentItem) {
      totalCost = calculateTotalCost(
        currentItem.item,
        currentItem.quantity,
        currentItem.quantityType
      );
    }
    currentItem.totalCost = totalCost;

    return currentItem;
  };
  const orderHistoryInput = (orderHistory) => {
    if (orderHistory) {
      return orderHistoryTotalCost(
        index,
        item,
        orderHistory.OrderQuantityType,
        orderHistory.OrderQuantity
      );
    } else {
      return {};
    }
  };
  const [orderItem, setOrderItem] = useState(() =>
    orderHistoryInput(orderHistory)
  );
  const priceString = buildPriceStringDescription(item);
  // console.log(orderHistory);

  const handleDropdownChange = (index, item, quantityTypeValue) => {
    const currentItem = { ...orderItem };
    currentItem.item = item;
    currentItem.quantityType = quantityTypeValue;
    var totalCost = null;
    if (currentItem) {
      totalCost = calculateTotalCost(
        currentItem.item,
        currentItem.quantity,
        currentItem.quantityType
      );
    }
    currentItem.totalCost = totalCost;
    if (totalCost !== null) {
      setReadyOrderItem(index, currentItem);
    } else {
      setReadyOrderItem(index, null);
    }
    setOrderItem(currentItem);
  };

  const handleQuantityChange = (index, item, quantityValue) => {
    const currentItem = { ...orderItem };
    currentItem.item = item;
    currentItem.quantity = Number(quantityValue);
    var totalCost = null;
    if (currentItem) {
      totalCost = calculateTotalCost(
        currentItem.item,
        currentItem.quantity,
        currentItem.quantityType
      );
    }
    currentItem.totalCost = totalCost;
    if (totalCost !== null) {
      setReadyOrderItem(index, currentItem);
    } else {
      setReadyOrderItem(index, null);
    }
    setOrderItem(currentItem);
  };

  const quantityTypeSelector = buildQuantityTypeSelector(
    item,
    index,
    handleDropdownChange,
    orderHistory
  );
  return (
    <React.Fragment>
      <Grid item xs={6} key={`${index}-description`}>
        {buildItemDescription(item, priceString)}
      </Grid>
      <Grid item xs={2} key={`${index}-quantityType`}>
        {quantityTypeSelector}
      </Grid>
      <Grid item xs={2} key={`${index}-quantityInput`}>
        {buildItemQuantityInput(
          item,
          index,
          handleQuantityChange,
          orderHistory
        )}
      </Grid>
      <Grid item xs={2} key={`${index}-totalCost`}>
        {orderItem && orderItem.totalCost ? "$" + numberWithCommas(orderItem.totalCost) : "N/A"}
      </Grid>
    </React.Fragment>
  );
};

function buildItemQuantityInput(item, index, onChange, orderHistory) {
  const previousQuantity = orderHistory ? orderHistory.OrderQuantity : "";
  return (
    <TextField
      inputProps={{ type: "number", max: 100, min: 0 }}
      onChange={(event) => onChange(index, item, event.target.value)}
      label="Quantity"
      defaultValue={previousQuantity}
      key={`${index}-quantityTextField`}
    />
  );
}

function buildQuantityTypeSelector(item, index, onChange, orderHistory) {
  const previousQuantityType = orderHistory
    ? orderHistory.OrderQuantityType
    : "";
  var quantityTypes = [];
  if (item.pricePerPoundFullCase || item.pricePerPoundPerHalfCase) {
    quantityTypes.push({ label: "lbs", value: "lb" });
  }
  if (item.pricePerCase && item.productGroup !== "Fresh Herbs") {
    quantityTypes.push({ label: "cases", value: "cs" });
  }
  if (item.pricePerEach) {
    quantityTypes.push({ label: "each", value: "ea" });
  }
  return (
    <FormControl fullWidth>
      <InputLabel>Type</InputLabel>
      <Select
        label="Quantity Type"
        onChange={(event) => onChange(index, item, event.target.value)}
        defaultValue={previousQuantityType}
      >
        {quantityTypes.map((quantityType, index) => {
          return (
            <MenuItem key={index} value={quantityType["value"]}>
              {quantityType["label"]}
            </MenuItem>
          );
        })}
      </Select>
    </FormControl>
  );
}

function buildItemDescription(item, priceString) {
  var grownText;
  if (item.grown) {
    grownText = (
      <Typography sx={{ fontSize: 12, fontWeight: "light" }}>
        {`Grown at ${item.grown}`}
      </Typography>
    );
  }
  return (
    <div>
      <Typography sx={{ fontWeight: "medium" }}>{item.label}</Typography>
      {grownText}
      <Typography sx={{ fontSize: 12, fontWeight: "light" }}>
        {priceString}
      </Typography>
    </div>
  );
}

function buildPriceStringDescription(item) {
  var priceString = "";
  if (item.pricePerPoundFullCase && item.pricePerPoundHalfCase) {
    priceString +=
      "$" +
      item.pricePerPoundFullCase +
      "/lbs full case, $" +
      item.pricePerPoundHalfCase +
      "/lbs partial case | case = " +
      item.poundsPerCase +
      "lbs";
  } else if (item.pricePerPoundFullCase) {
    priceString += "$" + item.pricePerPoundFullCase + "/lbs";
  }
  if (item.pricePerCase) {
    if (priceString !== "") {
      priceString += ", ";
    }
    priceString += "$" + item.pricePerCase + "/case";
  }
  if (item.pricePerEach) {
    if (priceString !== "") {
      priceString += ", ";
    }
    priceString += "$" + item.pricePerEach + "/each";
  }
  return priceString;
}

function numberWithCommas(x) {
  return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

export default React.memo(OrderItemRow);
