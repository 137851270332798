import "@aws-amplify/ui-react/styles.css";
import Grid from "@mui/material/Grid";
import React from "react";
import Typography from "@mui/material/Typography";

function OrderSummary(props) {
  const orderItems = props.orderItems;
  var overallTotal = 0;
  var overallTotalComponent;

  const orderSummaryRows = orderItems
    .filter((orderItem) => {
      return (
        orderItem !== null && orderItem !== undefined && orderItem.quantity > 0
      );
    })
    .map((orderItem) => {
      overallTotal += orderItem.totalCost;
      return (
        <React.Fragment>
          <Grid item xs={3}>
            <Typography sx={{fontWeight: "medium"}}>
              {orderItem.item.label}
            </Typography>
          </Grid>
          <Grid item xs={3}>
            <Typography sx={{fontWeight: "medium"}}>
              {orderItem.item.productGroup}{" "}
            </Typography>
          </Grid>
          <Grid item xs={2}>
            <Typography sx={{fontWeight: "medium"}}>
              {orderItem.quantity}{" "}
            </Typography>
          </Grid>
          <Grid item xs={2}>
            <Typography sx={{fontWeight: "medium"}}>
              {orderItem.quantityType}{" "}
            </Typography>
          </Grid>
          <Grid item xs={2}>
            <Typography sx={{fontWeight: "medium"}}>
              {"$"}
              {numberWithCommas(orderItem.totalCost.toFixed(2))}{" "}
            </Typography>
          </Grid>
        </React.Fragment>
      );
    });
  if (overallTotal > 0) {
    overallTotalComponent = (
      <React.Fragment>
        <Grid item xs={3}></Grid>
        <Grid item xs={3}></Grid>
        <Grid item xs={2}></Grid>
        <Grid item xs={2}></Grid>
        <Grid item xs={2}>
          <Typography sx={{fontWeight: "medium"}}>
            {"$"}
            {numberWithCommas(overallTotal.toFixed(2))}
          </Typography>
        </Grid>
      </React.Fragment>
    );
  }
  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <h2>Order Summary</h2>
      </Grid>
      <Grid item xs={3}>
        <h3>Product</h3>
      </Grid>
      <Grid item xs={3}>
        <h3>Category</h3>
      </Grid>
      <Grid item xs={2}>
        <h3>Quantity</h3>
      </Grid>
      <Grid item xs={2}>
        <h3>Quantity Type</h3>
      </Grid>
      <Grid item xs={2}>
        <h3>Cost</h3>
      </Grid>
      {orderSummaryRows}
      {overallTotalComponent}
    </Grid>
  );
}

function numberWithCommas(x) {
  return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

export default OrderSummary;
