import "@aws-amplify/ui-react/styles.css";
import "./App.css";
import OrderFormV2 from "./components/OrderFormV2";
import {
  withAuthenticator,
  useAuthenticator,
  Button,
  View,
} from "@aws-amplify/ui-react";
import React, { useEffect, useState } from "react";
import CircularProgress from "@mui/material/CircularProgress";
import Box from "@mui/material/Box";
import useGoogleSheets from "use-google-sheets";
import { post } from "aws-amplify/api";
import _ from "lodash";
import ContactPage from "./components/ContactPage";
import { cleanPriceListData } from "./helpers/PriceListHelper";
import NavBar from "./components/NavBar";
import InfoBar from "./components/InfoBar";
import "@fontsource/rubik";

function App({ signOut }) {
  const { user } = useAuthenticator((context) => [context.user]);

  async function fetch_user(userName) {
    var request = {
      requestType: "fetchUser",
      userName: userName,
    };
    try {
      const restOperation = post({
        apiName: "LocalHarvestApi",
        path: "/local-harvest-api",
        options: {
          body: request,
        },
      });
      const { body } = await restOperation.response;
      const user_json = await body.json();
      console.log("POST call succeeded");
      console.log(user_json);
      return user_json;
    } catch (e) {
      console.log("POST call failed: ", e);
    }
  }

  async function read_order(userName) {
    var request = {
      requestType: "readOrder",
      userName: userName,
    };
    try {
      const restOperation = post({
        apiName: "LocalHarvestApi",
        path: "/local-harvest-api",
        options: {
          body: request,
        },
      });
      const { body } = await restOperation.response;
      const order_json = await body.json();
      console.log("POST call succeeded");
      console.log(order_json);
      return order_json;
    } catch (e) {
      console.log("POST call failed: ", e);
    }
  }
  const [userData, setUserData] = useState(null);

  useEffect(() => {
    async function fetchUserData() {
      try {
        const userJson = await fetch_user(user.username);
        setUserData(userJson);
      } catch (error) {
        console.error("Failed to fetch user data: ", error);
      }
    }

    fetchUserData();
  }, [user.username]);

  const [orderData, setReadOrder] = useState(null);

  useEffect(() => {
    async function readOrder() {
      try {
        const userJson = await read_order(user.username);
        setReadOrder(userJson);
      } catch (error) {
        console.error("Failed to fetch user data: ", error);
      }
    }

    readOrder();
  }, [user.username]);

  const priceListFetch = FetchPriceList();

  if (priceListFetch.loading || userData === null || orderData === null) {
    return (
      <Box
        sx={{
          display: "flex",
          position: "absolute",
          left: "50%",
          top: "50%",
          transform: "translate(-50%, -50%)",
        }}
      >
        <CircularProgress />
      </Box>
    );
  }

  if (priceListFetch.error) {
    return (
      <div>
        There was an error loading the order form. Please contact
        orders@localharvesthawaii.com and we'll fix it right away!
      </div>
    );
  }

  if (_.isEmpty(userData)) {
    return (
      <View className="App">
        <ContactPage user={user.username} />
        <Button id="myButton" onClick={signOut}>
          Sign Out
        </Button>
      </View>
    );
  }

  const processedPriceListData = cleanPriceListData(priceListFetch);

  return (
    <View className="App">
      <NavBar user={user.username} signOut={signOut} />
      <InfoBar />
      <OrderFormV2
        data={processedPriceListData}
        user={userData}
        order={orderData}
      />
      <br></br>
      <Button onClick={signOut}>Sign Out</Button>
    </View>
  );
}

function FetchPriceList() {
  const apiKey = "AIzaSyB9Ogq4Lv7GrQYCZ4ikq6rMu4rhnupiWk8";
  const { data, loading, error } = useGoogleSheets({
    apiKey: apiKey,
    sheetId: "1sDW8SjhyQLbxrVCWtbH0a-btQgb_Ok-ZBYSl2xb_25U",
    sheetsOptions: [{ id: "ActivePriceList" }],
  });
  return {
    data: data,
    loading: loading,
    error: error,
  };
}

export default withAuthenticator(App);
