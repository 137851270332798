import "@aws-amplify/ui-react/styles.css";
import Grid from "@mui/material/Grid";
import React, { useState, useCallback, useEffect } from "react";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import OrderItemRow from "./OrderItemRow";
import OrderSummary from "./OrderSummary";
import { sendCreateOrder } from "../helpers/CreateOrder";
import LoadingButton from "@mui/lab/LoadingButton";
import SendIcon from "@mui/icons-material/Send";

function OrderFormV2(props) {
  console.log("props.data", props.data);

  const buildIntialOrderItems = (itemList, orderHistoryList) => {
    if (orderHistoryList && orderHistoryList.length > 0) {
      var initialOrderItems = [];
      console.log("Initializing state:");
      console.log(itemList);
      console.log(orderHistoryList);
      orderHistoryList.forEach((order) => {
        itemList.forEach((item) => {
          if (order.ProductName === item.label) {
            var orderItem = {};
            orderItem.item = item;
            orderItem.quantity = order.OrderQuantity;
            orderItem.quantityType = order.OrderQuantityType;
            orderItem.totalCost = order.Cost;
            initialOrderItems[item.id] = orderItem;
          }
        });
      });
      console.log("initialOrderItems", initialOrderItems);
      return initialOrderItems;
    } else {
      return [];
    }
  };
  const readOrder = props.order;
  console.log("readOrder", readOrder);
  const [orderItems, setOrderItems] = useState(() =>
    buildIntialOrderItems(props.data, readOrder)
  );
  const [hasValidOrder, setHasValidOrder] = useState(false);
  const [isLoadingCreateOrder, setIsLoadingCreateOrder] = useState(false);
  const [isOrderPlaced, setIsOrderPlaced] = useState("NotPlaced");
  const userData = props.user;
  const setReadyOrderItem = useCallback((index, orderItem) => {
    setOrderItems((prevOrderItems) => {
      const newOrderItems = [...prevOrderItems];
      newOrderItems[index] = orderItem;
      console.log("Order items set to:", newOrderItems);
      return newOrderItems;
    });
  }, []);

  useEffect(() => {
    const numberValidItems = orderItems.filter((orderItem) => {
      return (
        orderItem !== null && orderItem !== undefined && orderItem.quantity > 0
      );
    }).length;
    if (numberValidItems > 0) {
      setHasValidOrder(true);
    } else {
      setHasValidOrder(false);
    }
  }, [orderItems]);

  const orderItem = () => {
    sendCreateOrder(
      orderItems,
      userData,
      setIsLoadingCreateOrder,
      setIsOrderPlaced
    );
  };
  const dataByProductGroup = Object.groupBy(
    props.data,
    ({ productGroup }) => productGroup
  );
  var outerIndex = 0;
  const accordionEntries = Object.entries(dataByProductGroup).map(
    ([productGroup, items]) => {
      const gridRows = [];
      items
        .sort((a, b) =>
          a.label.localeCompare(b.label, undefined, { numeric: true })
        )
        .forEach((item, innerIndex) => {
          const index = outerIndex + innerIndex;
          item.id = index;
          // console.log(readOrder);
          var orderHistory;
          readOrder.forEach((order) => {
            if (order.ProductName == item.label) {
              // console.log(order.ProductName);
              orderHistory = order;
            }

            // console.log(item);
            // console.log(order);
          });
          // Sometimes the availablePounds is 0 not null, so filter these out here. 
          if (item.availablePounds && item.availablePounds > 0) {
            gridRows.push(
              <OrderItemRow
                index={index}
                item={item}
                setReadyOrderItem={setReadyOrderItem}
                orderHistory={orderHistory}
              />
            );
          }
        });
      outerIndex += items.length;
      return (
        <div>
          <Accordion key={`${productGroup}-accordionTopLevel`}>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
              key={`${productGroup}-accordionSummary`}
            >
              <Typography
                sx={{ fontWeight: "medium" }}
                key={`${productGroup}-title`}
              >
                {productGroup}
              </Typography>
            </AccordionSummary>
            <AccordionDetails key={`${productGroup}-accordionDetails`}>
              <Grid
                container
                spacing={2}
                key={`${productGroup}-containerSpacing`}
              >
                <Grid
                  item
                  xs={6}
                  sx={{ fontWeight: "medium" }}
                  key={`${productGroup}-header-description`}
                >
                  Description
                </Grid>
                <Grid
                  item
                  xs={2}
                  sx={{ fontWeight: "medium" }}
                  key={`${productGroup}-header-quantityType`}
                >
                  Quantity Type
                </Grid>
                <Grid
                  item
                  xs={2}
                  sx={{ fontWeight: "medium" }}
                  key={`${productGroup}-header-quantityInput`}
                >
                  Quantity
                </Grid>
                <Grid
                  item
                  xs={2}
                  sx={{ fontWeight: "medium" }}
                  key={`${productGroup}-header-totalCost`}
                >
                  Total Cost
                </Grid>
                {gridRows}
              </Grid>
            </AccordionDetails>
          </Accordion>
        </div>
      );
    }
  );
  var createOrderButton;
  if (hasValidOrder) {
    if (isLoadingCreateOrder) {
      createOrderButton = (
        <LoadingButton
          variant="contained"
          onClick={orderItem}
          endIcon={<SendIcon />}
          loadingPosition="end"
          loading
        >
          <span>Create Order</span>
        </LoadingButton>
      );
    } else {
      createOrderButton = (
        <LoadingButton
          variant="contained"
          onClick={orderItem}
          endIcon={<SendIcon />}
          loadingPosition="end"
        >
          <span>Create Order</span>
        </LoadingButton>
      );
    }
  } else {
    createOrderButton = (
      <LoadingButton variant="contained" onClick={orderItem} disabled>
        Create Order
      </LoadingButton>
    );
  }
  if (isOrderPlaced === "PlacedSuccessfully") {
    return (
      <div>
        <Typography variant="h6" gutterBottom>
          Order placed successfully. Please refresh the page to place another
          order. If you made a mistake while placing this order, please contact
          Cam to modify or change your existing order. Thank you!
        </Typography>
      </div>
    );
  } else if (isOrderPlaced === "Error") {
    return (
      <div>
        <Typography variant="h6" gutterBottom>
          There was an error when placing your order. To try again, please
          refresh the page to place another order. If things continue to not
          work, please contact Cam to manually place your order. Thank
          you, and sorry for the inconvenience!
        </Typography>
      </div>
    );
  }

  return (
    <div>
      {accordionEntries}
      <br></br>
      <OrderSummary orderItems={orderItems} />
      <br></br>
      {createOrderButton}
    </div>
  );
}

export default OrderFormV2;
