import "@aws-amplify/ui-react/styles.css";
import "./InfoBar.css";
import React from "react";
import "@fontsource/rubik";
import { View } from "@aws-amplify/ui-react";

function NavBar(props) {
  return (
    <View>
      <body className="InfoBar">
        Place an order in between Monday 8AM and Thursday 8AM for a Friday/Saturday Delivery. <br />
        Place an order in between Thursday 8AM to Monday 8AM for a Tuesday/Wednesday Delivery. <br />
      </body>
    </View>
  );
}

export default NavBar;
