import { post } from "aws-amplify/api";

export const sendCreateOrder = (
  orderItems,
  userData,
  setIsLoadingCreateOrder,
  setIsOrderPlaced
) => {
  setIsLoadingCreateOrder(true);
  console.log("Sending the create order request", orderItems, userData);
  var mappedUserData = {
    userName: userData.userName,
    mosDisplayname: userData.displayName,
    clientPickupType: userData.pickupType,
    clientRegion: userData.pickupRegion,
  };

  var orderWriteRequest = {
    requestType: "createOrder",
    user: mappedUserData,
  };

  var orderItemsRequest = orderItems
    .filter((orderItem) => {
      return (
        orderItem !== null && orderItem !== undefined && orderItem.quantity > 0
      );
    })
    .map((orderItem) => {
      return {
        product: orderItem.item,
        quantity: orderItem.quantity,
        quantityType: orderItem.quantityType,
        cost: orderItem.totalCost,
      };
    });

  orderWriteRequest.orderItems = orderItemsRequest;

  console.log("Full orderWriteRequest", JSON.stringify(orderWriteRequest));
  sendCreateOrderRequest(
    orderWriteRequest,
    setIsLoadingCreateOrder,
    setIsOrderPlaced
  );
};

async function sendCreateOrderRequest(
  request,
  setIsLoadingCreateOrder,
  setIsOrderPlaced
) {
  try {
    const restOperation = post({
      apiName: "LocalHarvestApi",
      path: "/local-harvest-api",
      options: {
        body: request,
      },
    });
    await restOperation.response;
    console.log("POST call succeeded");
    console.log(restOperation.response);
    setIsOrderPlaced("PlacedSuccessfully");
  } catch (e) {
    console.log("POST call failed: ", e);
    setIsOrderPlaced("Error");
  }
  setIsLoadingCreateOrder(false);
}
