export const calculateTotalCost = (item, quantity, quantityType) => {
  // console.log("in calculator: ", item, quantity, quantityType);
  if (!item || !quantity || !quantityType) {
    return null;
  }

  if (quantityType === "lb") {
    if (!item.pricePerPoundHalfCase) {
      const price = quantity * item.pricePerPoundFullCase;
      return price;
    }
    if (quantity < item.poundsPerCase) {
      const price = quantity * item.pricePerPoundHalfCase;
      return price;
    }
    if (quantity >= item.poundsPerCase) {
      const price = quantity * item.pricePerPoundFullCase;
      return price;
    }
  }

  if (quantityType === "cs") {
    const price = quantity * item.pricePerCase;
    return price;
  }

  if (quantityType === "ea") {
    const price = quantity * item.pricePerEach;
    return price;
  }
};
