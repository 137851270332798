import "@aws-amplify/ui-react/styles.css";
import "./ContactPage.css";
import { View } from "@aws-amplify/ui-react";
import React from "react";
import "@fontsource/rubik";

function ContactPage(props) {
  return (
    <View className="ContactPage-header">
      <h1>
        {" "}
        Before accessing the order form, please register your username,{" "}
        {props.user}.
      </h1>

      <br></br>
      <h2>
        Please contact Cam at{" "}
        <a href="mailto:orders@localharvesthawaii.com" target="_blank">
          orders@localharvesthawaii.com
        </a>{" "}
        or 111-222-3333.
      </h2>
    </View>
  );
}

export default ContactPage;
