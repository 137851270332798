import "@aws-amplify/ui-react/styles.css";
import "./NavBar.css";
import React from "react";
import "@fontsource/rubik";
import Logo from "../images/Local Harvest logo.png";
import { View } from "@aws-amplify/ui-react";
import Link from "@mui/material/Link";

function NavBar(props) {
  return (
    <View>
      <body className="NavBar">
        <div>
          <Link href="#" onClick={props.signOut}>
            SIGN OUT
          </Link>
          <a href="https://www.localharvesthawaii.com/">LOCAL HARVEST HOME</a>
        </div>
        <div class="NavBar-Main">
          <img src={Logo} />
        </div>
        <div class="NavBar-Right"> Welcome, {props.user}!</div>
      </body>
    </View>
  );
}

export default NavBar;
